import React from "react";

export const DataStatsCard = ({stat, amount}: {stat:string, amount: number}) =>{

    return <div className="rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark md:p-6 xl:p-7.5">
        <div className="flex items-end justify-between">
            <div>
                <h3 className="mb-4 text-title-lg font-bold text-black dark:text-white">
                    {amount}
                </h3>
                <p className="font-medium">{stat}</p>
                <span className="mt-2 flex items-center gap-2">
              </span>
            </div>

            <div>
                <svg className="h-17.5 w-17.5 -rotate-90 transform">
                    <circle
                        className="text-stroke dark:text-strokedark"
                        strokeWidth="10"
                        stroke="currentColor"
                        fill="transparent"
                        r="30"
                        cx="35"
                        cy="35"
                    />
                    <circle
                        className="text-primary"
                        strokeWidth="10"
                        strokeDasharray={30 * 2 * Math.PI}
                        strokeDashoffset={
                            30 * 2 * Math.PI - (60 / 100) * 30 * 2 * Math.PI
                        }
                        stroke="currentColor"
                        fill="transparent"
                        r="30"
                        cx="35"
                        cy="35"
                    />
                </svg>
            </div>
        </div>
    </div>

}