"use client";
import React from "react";
import DataStatsThree from "../DataStats/DataStatsThree";
import ChartSeven from "../Charts/ChartSeven";
import ChartEight from "../Charts/ChartEight";
import ChartNine from "../Charts/ChartNine";
import LeadsReport from "../LeadsReport";
import ToDoList from "../Todo/ToDoList";
import {DataStatsCard} from "@/components/DataStats/DataStatsCard";
import DropdownDefault from "@/components/Dropdowns/DropdownDefault";

const CRM = ({statistics}:{statistics:any}) => {
    return (
        <>
            <div className="mb-5 flex items-center justify-between">
                <div>
                    <h2 className="mb-1.5 text-title-md2 font-bold text-black dark:text-white">
                        Dashboard
                    </h2>
                </div>
                <DropdownDefault />
            </div>
            <DataStatsThree title={'Dati economici'}>
                <DataStatsCard stat={'Fatturato'} amount={100}/>
                <DataStatsCard stat={'Utile'} amount={100}/>
                <DataStatsCard stat={'Margine'} amount={100}/>
            </DataStatsThree>
            <DataStatsThree title={'Clienti'}>
                <DataStatsCard stat={'Nuovi clienti'} amount={100}/>
                <DataStatsCard stat={'Clienti chiusi'} amount={100}/>
            </DataStatsThree>
            <DataStatsThree title={'Preventivi'}>
                <DataStatsCard stat={'Preventivi creati'} amount={statistics.quotes.number}/>
                <DataStatsCard stat={'Preventivi in corso'} amount={statistics.quotes.wip}/>
                <DataStatsCard stat={'Preventivi chiusi'} amount={statistics.quotes.closed}/>
                <DataStatsCard stat={'Valore preventivi chiusi'} amount={statistics.quotes.total}/>
            </DataStatsThree>
            <DataStatsThree title={'Contratti'}>
                <DataStatsCard stat={'Contratti aperti'} amount={100}/>
                <DataStatsCard stat={'Contratti in corso'} amount={100}/>
                <DataStatsCard stat={'Contratti chiusi'} amount={100}/>
                <DataStatsCard stat={'Valore contratti chiusi'} amount={100}/>
            </DataStatsThree>

            <DataStatsThree title={'Consulenti'}>
                <DataStatsCard stat={'Fatturato per consulente'} amount={100}/>
            </DataStatsThree>
            <div className="mt-7.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
                <div className="col-span-12 xl:col-span-7">
                    <ChartSeven/>
                </div>

                <div className="col-span-12 xl:col-span-5">
                  <ChartEight />
                </div>


                <div className="col-span-12 xl:col-span-5">
                  <ChartNine />
                </div>

            </div>
        </>
    );
};

export default CRM;
